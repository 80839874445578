<template>
  <div>
    <!--    <div class="row"> -->
      <div class="col-12 card">
      <label style="padding-right: 20px;"><h5>{{ $t('History Days') }}</h5></label>
      <el-select
        class="select-primary mb-3 pagination-select"
        v-model="days"
        :placeholder="$t('History Days')"
        >
        <el-option
          class="select-primary"
          v-for="item in daysList"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>
    <div class="row">
      <!-- Stats Cards -->
      <div class="col-lg-3 col-md-6">
        <stats-card
          :title="income.toFixed(2)"
          :sub-title="$t('Income')"
          type="info"
          icon="tim-icons icon-coins"
        >
          <div slot="footer"><b><i class="tim-icons icon-alert-circle-exc"></i> {{ $t('Total Income') }}</b></div>
        </stats-card>
      </div>
      <div class="col-lg-3 col-md-6">
        <stats-card
          :title="expenses.toFixed(2)"
          :sub-title="$t('Expenses')"
          type="danger"
          icon="tim-icons icon-delivery-fast"
        >
          <div slot="footer"><b><i class="tim-icons icon-alert-circle-exc"></i> {{ $t('Total Expenses') }}</b></div>
        </stats-card>
      </div>
      <div class="col-lg-3 col-md-6">
        <stats-card
          :title="profit.toFixed(2)"
          :sub-title="$t('Profit')"
          :type="profitType"
          :icon="profitIcon"
        >
          <div slot="footer"><b><i class="tim-icons icon-alert-circle-exc"></i> {{ $t('Net Profit') }}</b></div>
        </stats-card>
      </div>
    </div>
  </div>
</template>
<script>
  import StatsCard from 'src/components/Cards/StatsCard';
  import config from '@/config';
  import { Select, Option } from 'element-ui';
  export default {
    name: 'accounting-reports',
    components: {
      StatsCard,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
        days: 1,
        daysList: [1, 2, 3, 7, 15, 30, 60, 90, 120, 180, 270, 365, 730],
        income: 0,
        expenses: 0,
      };
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      profit() {
        return this.income-this.expenses;
      },
      profitType() {
        return (this.profit <= 0)? 'warning':'success';
      },
      profitIcon() {
        return (this.profit <= 0)? 'tim-icons icon-bell-55':'tim-icons icon-satisfied';
      }
    },
    watch: {
      days(val) {
        this.getReports();
      }
    },
    methods: {
      getReports(){
        this.$http.get('accountingreports/'+this.days)
        .then(response => response.json())
        .then(response => {
          this.tableData = response.expireSoon;
          this.income = response.income || 0;
          this.expenses = response.expenses || 0;
        })
        .catch(response => {
          if (response.status == 403)  this.$router.push('/logout');
        })
      },
      notifyVue(message = null, type = 'info', icon = 'tim-icons icon-bell-55', verticalAlign = 'top', horizontalAlign = 'right') {
        this.$notify({
            message,
            timeout: 15000,
            icon,
            horizontalAlign,
            verticalAlign,
            type
        });
      }
    },
    mounted() {
      this.getReports();
      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      };
    },
    beforeDestroy() {
      if (this.$rtl.isRTL) {
        this.i18n.locale = 'en';
        this.$rtl.disableRTL();
      }
    },
  };
</script>
<style></style>
